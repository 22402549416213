@font-face {
	font-family: 'Play';
	src: url('/assets/fonts/play/Play-Bold.eot');
	src: url('/assets/fonts/play/Play-Bold.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/play/Play-Bold.woff2') format('woff2'),
	url('/assets/fonts/play/Play-Bold.woff') format('woff'),
	url('/assets/fonts/play/Play-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Play';
	src: url('/assets/fonts/play/Play.eot');
	src: url('/assets/fonts/play/Play.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/play/Play.woff2') format('woff2'),
	url('/assets/fonts/play/Play.woff') format('woff'),
	url('/assets/fonts/play/Play.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

