@font-face {
	font-family: 'Open Sans';
	src: url('/assets/fonts/opensans/OpenSans.eot');
	src: url('/assets/fonts/opensans/OpenSans.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/opensans/OpenSans.woff2') format('woff2'),
	url('/assets/fonts/opensans/OpenSans.woff') format('woff'),
	url('/assets/fonts/opensans/OpenSans.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/assets/fonts/opensans/OpenSans-Semibold.eot');
	src: url('/assets/fonts/opensans/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/opensans/OpenSans-Semibold.woff2') format('woff2'),
	url('/assets/fonts/opensans/OpenSans-Semibold.woff') format('woff'),
	url('/assets/fonts/opensans/OpenSans-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/assets/fonts/opensans/OpenSans-Bold.eot');
	src: url('/assets/fonts/opensans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/opensans/OpenSans-Bold.woff2') format('woff2'),
	url('/assets/fonts/opensans/OpenSans-Bold.woff') format('woff'),
	url('/assets/fonts/opensans/OpenSans-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/assets/fonts/opensans/OpenSans-Extrabold.eot');
	src: url('/assets/fonts/opensans/OpenSans-Extrabold.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/opensans/OpenSans-Extrabold.woff2') format('woff2'),
	url('/assets/fonts/opensans/OpenSans-Extrabold.woff') format('woff'),
	url('/assets/fonts/opensans/OpenSans-Extrabold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/assets/fonts/opensans/OpenSans-Light.eot');
	src: url('/assets/fonts/opensans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/opensans/OpenSans-Light.woff2') format('woff2'),
	url('/assets/fonts/opensans/OpenSans-Light.woff') format('woff'),
	url('/assets/fonts/opensans/OpenSans-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

